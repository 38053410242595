import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { Heading, Text } from "~/components/common/Typography"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import Copyright from "~/components/common/Copyright"
import CustomPlayer from "~/components/common/CustomPlayer"

const Page = ({ myPage = 2, mySubpage = 4 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "3.5 HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video6_CTM_190923_3bit" }
          ) {
              name
              publicURL
          }
          graphic1: file(name: { eq: "3.5a-ADIDAS-calendar" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "3.5b-ADIDAS-microphone" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "3.5c-ADIDAS-circle" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, video, graphic1, graphic2, graphic3 } = data

  const graphics = [
    {
      header: "Consistent:",
      text:
        "It happens regularly and often. Girls need to practice this and know it’s coming.",
      graphic: graphic1,
    },
    {
      header: "Players first:",
      text:
        "You, the coach, get the mic for the rest of practice, but team time is for the girls. It’s not about what you want to talk about; it’s what they want to talk about.",
      graphic: graphic2,
    },
    {
      header: "Round:",
      text:
        "Circles allow everyone to feel like a part of the group. As a coach, you can ask open-ended questions so the girls can think, respond, and share.",
      graphic: graphic3,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Gatorade baths. Secret handshakes. Pregame team dinners. Sports are rich in tradition, and for good reason."
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase>
        Traditions are like magnets. They draw people in. They make people feel
        like they are a part of something special. Some girls might not only
        love the sport itself, but also the traditions and connections it
        provides. <br/>
        <br/>
        Traditions aren’t extraneous to the program: they serve an important
        purpose both in terms of prosocial connections and actual sports
        performance.
        <br/>
        <br/>
        In addition to establishing strict behavior codes, enforcing
        non-negotiables, and cultivating friendships, traditions are another way
        of creating a positive team culture in the program.
      </Infobox>

      <InfoboxDetailed
        as={"section"}
        headerMaxWidth={"desktop"}
        headerText={"This is what traditions look like:"}
      >
        <Flex
          flexDirection={["column", "row"]}
          maxWidth={"desktop"}
          mx={"auto"}
          mt={6}
        >
          <Box
            width={["100%", "50%"]}
            textAlign={["center", "left"]}
            pl={[0, 7]}
            pr={[0, 6]}
            fontSize={["16px", "18px"]}
            lineHeight={["24px", "36px"]}
          >
            <Text mt={[0]}>
              First and foremost, traditions should be fun and unique to your
              team. But they can also provide informal learning experiences that
              will enrich girls’ lives.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              As a coach, you can create moments in time on a regular basis
              where this kind of learning happens. This is often called{" "}
              <span
                css={css`
                  font-family: "AdineuePROBoldItalicWeb";
                `}
              >
                reflection.
              </span>{" "}
              In sports, we call it{" "}
              <span
                css={css`
                  font-family: "AdineuePROBoldItalicWeb";
                `}
              >
                {" "}
                “team time.”
              </span>
            </Text>
          </Box>
          <Box width={["100%", "50%"]}>
            <Box width={"100%"} maxWidth={["253px", "337px"]} mx={"auto"}>
              <CustomPlayer url={video.publicURL} videoLabel={video.name} vertical></CustomPlayer>
            </Box>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        headerText={
          "There are only three required elements to successful team time"
        }
        py={6}
      >
        <Box
          bg={"white-panel"}
          maxWidth={"desktop"}
          mx={"auto"}
          mt={[5, 6]}
          px={[5, 5, 6]}
          py={[3, 6]}
        >
          <Heading as={"h2"}>TEAM TIME</Heading>
          <Flex
            maxWidth={"desktop"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"start"}
            my={6}
            css={css`
              flex-wrap: wrap;
            `}
          >
            {graphics.map((item, index) => (
              <GraphicWithText
                image={item.graphic}
                width={["100%", "33%"]}
                px={5}
                key={index}
                mq={mq({
                  width: ["50%", "80%"],
                })}
              >
                <Text my={0}>
                  <span
                    css={css`
                      font-family: "AdineuePROBoldWeb";
                      text-transform: uppercase;
                    `}
                  >
                    {item.header}
                  </span>
                </Text>
                <Text mt={0}>{item.text}</Text>
              </GraphicWithText>
            ))}
          </Flex>
          <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
            <Text>
              Team time also provides an opportunity for the girls to give
              feedback to you. As a coach, you could be bringing drills and
              challenges to the team that are drawn from the most respected and
              successful professional programs. But if your girls aren’t getting
              anything out of them, it is your responsibility to adjust the
              practices to foster growth. Team time is a valuable resource for
              you to check your work and pivot if needed.
            </Text>
          </Box>
        </Box>
      </InfoboxDetailed>
      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 2, 4)
